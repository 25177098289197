<template>
    <div class="white-bg">
		<!-- Main content -->
		<div class="main-wrap">
			<div class="container">
				<div class="col-md-12">
					<div class="sermon-content" v-for="(teac,index) in teach" :key="index">
						<div class="row">
							<div class="col-md-4">
								<iframe style="border:none;" width="100%"  :src="'https://www.youtube.com/embed/'+teac.lien">
                                  </iframe>
							</div>
							<div class="col-md-7">
								<h4>{{teac.titre}}</h4>
								<span class="post-meta">{{teac.auteur}}<a href="#"></a></span>
								<div class="clear"></div>
								<ul class="hb-social">
									<li class="hb-fb"><a href="#"><i class="fa fa-facebook"></i></a></li>
									<li class="hb-tw"><a href="#"><i class="fa fa-twitter"></i></a></li>
									<li class="hb-plus"><a href="#"><i class="fa fa-plus"></i></a></li>
								</ul>
							</div>
							<div class="col-md-5">
								<ul class="m-ico">
									<li><a href="#" class="m-ico1"></a></li>
									<li><a href="#" class="m-ico2"></a></li>
									<li><a href="#" class="m-ico3"></a></li>
								</ul>
							</div>
						</div>
					</div>

					<div class="page-meta">
						<div class="space20"></div>
						<ul class="page-nav">
							<li>Page 1 of 5 
                            </li>
							<li class="active"><a href="#">1</a>
                            </li>
							<li><a href="#">2</a>
                            </li>
							<li><a href="#">3</a>
                            </li>
							<li><a href="#">4</a>
                            </li>
							<li><a href="#">5</a>
                            </li>
						</ul>
						<ul class="page-nav-links">
							<li><a href="#">&laquo; Previous page</a></li>
							<li><a href="#">Next page &raquo;</a></li>
						</ul>
					</div>
				</div>
				<aside class="col-md-4">
					<div class="side-widget">
						<h5>Search</h5>
						<form class="search">
							<input type="search" placeholder="Type and search">
						</form>
					</div>
					<div class="space40"></div>
					<div class="side-widget">
						<h5>Sermon Topics</h5>
						<ul class="categories">
							<li><a href="#">Life Issues(31)</a></li>
							<li><a href="#">The Glory of God(25)</a></li>
							<li><a href="#">Family(16)</a></li>
							<li><a href="#">Education(12)</a></li>
						</ul>
					</div>
					<div class="space50"></div>
					<div class="side-widget">
						<h5>Sermon Occasions</h5>
						<ul class="categories">
							<li><a href="#">Christmas</a></li>
							<li><a href="#">Easter</a></li>
							<li><a href="#">Thanksgiving</a></li>
						</ul>
					</div>
					<div class="space50"></div>
					<div class="side-widget">
						<h5>Sermons</h5>
						<ul class="sermon-list">
							<li>
								<img class="s-thumb" src="demo/xtra/1.jpg" alt=""/>
								<h4>Walking in the<br>
									Footsteps of Faith
								</h4>
								<div class="clear"></div>
								<ul class="m-ico">
									<li><a href="#" class="m-ico1"></a></li>
									<li><a href="#" class="m-ico2"></a></li>
									<li><a href="#" class="m-ico3"></a></li>
								</ul>
							</li>
							<li>
								<img class="s-thumb" src="demo/xtra/2.jpg" alt=""/>
								<h4>Love the Way You Live</h4>
								<div class="clear"></div>
								<ul class="m-ico">
									<li><a href="#" class="m-ico1"></a></li>
									<li><a href="#" class="m-ico2"></a></li>
									<li><a href="#" class="m-ico3"></a></li>
								</ul>
							</li>
							<li>
								<img class="s-thumb" src="demo/xtra/3.jpg" alt=""/>
								<h4>Read Bible Everyday</h4>
								<div class="clear"></div>
								<ul class="m-ico">
									<li><a href="#" class="m-ico1"></a></li>
									<li><a href="#" class="m-ico2"></a></li>
									<li><a href="#" class="m-ico3"></a></li>
								</ul>
							</li>
						</ul>
					</div>
				</aside>
			</div>
		</div>
	</div>
</template>
<script>
import axios from 'axios';
import { JNEPCI_URL,JNEPCI } from '@/config'
export default {
    data: function () {
        return {
            teach:[],
            JNEPCI:JNEPCI,
            isLoading:false
        }

    },
    mounted:function(){
        this.getEnseignement()
    },
    methods:{
            getEnseignement: function() {
                let app = this;
                app.isLoading = true;
                axios.get(JNEPCI_URL+'enseignements-video')
                .then((response) => {
                console.log(response)
                    app.teach = response.data
                    this.isLoading = false;
                })
                .catch((   ) => {
                    this.isLoading = false;
                });
            },
    }
}

</script>
