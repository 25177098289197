<template>
  <div class="home">
    <div class="home-blog container">
            <div  class="col-md-4 hb-content text-truncate" v-for="(actualite,index) in actualites.slice(0,4)" :key="index">
                <div class="hb-meta"><span class="date">{{actualite.date}}</span>  <a href="#"></a></div>
                
                <h4><router-link :to="'/actualite-details/'+actualite.id">{{ actualite.titre.substring(0,23)+".."}}</router-link></h4>
                <img :src="JNEPCI+actualite.photo" height="200px" class="img-responsive img-thumbnail" alt="" />
                <p v-html="actualite.description.substring(3,220)+'...'"></p>
                <a :href="'/actualite-details/'+actualite.id" class="hb-more">Voir plus</a>
                <ul class="hb-social">
                    <li class="hb-fb"><a href="#"><i class="fa fa-facebook"></i></a></li>
                    <li class="hb-tw"><a href="#"><i class="fa fa-twitter"></i></a></li>
                    <li class="hb-plus"><a href="#"><i class="fa fa-plus"></i></a></li>
                </ul>
            </div>
        </div>
        <!-- Home Story -->

        <div class="container">
            <div class="col-md-16">
                <a href="/actualites" class="archive-link">Toutes les actualités</a>
            </div>
        </div>

        <!-- Quote -->
        <div class="container">
            <div class="quote-wrap">
                <div id="quote-slider" class="flexslider">
                    <ul class="slides d-flex flex-nowrap">
                        <li class="text-nowrap" v-for="(bur,index) in bureau.slice(0,10)" :key="index" :data-thumb="JNEPCI+bur.photo">
                            <img :src="JNEPCI+bur.photo" height="40px" alt="">
                            <p></p>
                            <p></p>
                            <p></p>
                            <h5 class="p-4">{{bur.nom}} {{bur.prenoms}}</h5>
                            <p></p>
                            <p> <strong>{{bur.poste.nom}}</strong>,{{bur.situation}}, provenant de la region {{bur.region.nom}} et {{bur.fonction_assemblee}} </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- Quote -->

        <!-- Donation Wrap -->
        <div class="donation-wrap" data-stellar-background-ratio="0.10">
            <div class="container">
                <div class="col-md-16">
                    <div id="donation-slider" class="flexslider">
                        <ul class="slides">
                            <li>
                                <h3>DEVENEZ PARTENAIRE DE LA RSN </h3>
                                <div class="d-price">5000F/mois</div>
                                <div class="d-progress"><span style="width:70%"></span></div>
                                <p>Budget estimé à 5Millions de Fcfa </p>
                                <a class="d-btn" href="#">Je donne</a>
                            </li>
                            <li>
                                <h3>DEVENEZ PARTENAIRE DE LA RSN </h3>
                                <div class="d-price">10000F/mois</div>
                                <div class="d-progress"><span style="width:70%"></span></div>
                                <p>Budget estimé à 5Millions de Fcfa</p>
                                <a class="d-btn" href="#">Je donne</a>
                            </li>
                            <li>
                                <h3>DEVENEZ PARTENAIRE DE LA RSN </h3>
                                <div class="d-price">15000/mois</div>
                                <div class="d-progress"><span style="width:70%"></span></div>
                                <p>Budget estimé à 5Millions de Fcfa</p>
                                <a class="d-btn" href="#">Je donne</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- Donation Wrap -->

        <!-- Sermons Wrap -->
        <div class="home-wrap">
            <div class="container">
                <div class="col-md-8 music-wrap">
                    <div class="row">
                        <div id="sermons-slider" class="owl-carousel">
                            <div class="item">
                                <div class="col-md-8">
                                  <iframe style="border:none;" width="80%" height="300" :src="'https://www.youtube.com/embed/8v89stxQoGo'">
                                  </iframe>
                                </div>
                                <div class="col-md-8">
                                    <h4>Resumé</h4>
                                    <div class="music-info">
                                        <h5>Retraite spirituelle nationale</h5>
                                        <p>Jnepci /  15 Aout,2022 / <a href="#">Abengourou</a></p>
                                    </div>
                                    <ul class="m-ico">
                                        <li>
                                            <a href="#" class="m-ico1"></a>
                                        </li>
                                        <li>
                                            <a href="#" class="m-ico2"></a>
                                        </li>
                                        <li>
                                            <a href="#" class="m-ico3"></a>
                                        </li>
                                    </ul>
                                    <div class="line"></div>
                                    
                                </div>
                            </div>
                            <div class="item">
                                <div class="col-md-8">
                                  <iframe style="border:none;" width="80%" height="300" :src="'https://www.youtube.com/embed/8v89stxQoGo'">
                                  </iframe>
                                </div>
                                <div class="col-md-8">
                                    <h4>Resumé</h4>
                                    <div class="music-info">
                                        <h5>Retraite spirituelle nationale</h5>
                                        <p>Jnepci /  15 Aout,2022 / <a href="#">Abengourou</a></p>
                                    </div>
                                    <ul class="m-ico">
                                        <li>
                                            <a href="#" class="m-ico1"></a>
                                        </li>
                                        <li>
                                            <a href="#" class="m-ico2"></a>
                                        </li>
                                        <li>
                                            <a href="#" class="m-ico3"></a>
                                        </li>
                                    </ul>
                                    <div class="line"></div>
                                    
                                </div>
                            </div>
                            <div class="item">
                                <div class="col-md-8">
                                  <iframe style="border:none;" width="80%" height="300" :src="'https://www.youtube.com/embed/8v89stxQoGo'">
                                  </iframe>
                                </div>
                                <div class="col-md-8">
                                    <h4>Resumé</h4>
                                    <div class="music-info">
                                        <h5>Retraite spirituelle nationale</h5>
                                        <p>Jnepci /  15 Aout,2022 / <a href="#">Abengourou</a></p>
                                    </div>
                                    <ul class="m-ico">
                                        <li>
                                            <a href="#" class="m-ico1"></a>
                                        </li>
                                        <li>
                                            <a href="#" class="m-ico2"></a>
                                        </li>
                                        <li>
                                            <a href="#" class="m-ico3"></a>
                                        </li>
                                    </ul>
                                    <div class="line"></div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Sermons Wrap -->

                <!-- Events Wrap -->
                <div class="col-md-8 events-wrap">
                    <ul>
                        <li>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="date">20<span>Aout</span></div>
                                    <h4>Retraite spirituelle nationale 2023 <span>9eme edition</span></h4>
                                    <div class="time">du jeudi au dimanche</div>
                                </div>
                                <div class="col-md-4">
                                    <ul class="hb-social">
                                        <li class="hb-fb"><a href="#"><i class="fa fa-facebook"></i></a></li>
                                        <li class="hb-tw"><a href="#"><i class="fa fa-twitter"></i></a></li>
                                        <li class="hb-plus"><a href="#"><i class="fa fa-plus"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="date">25<span>Nov</span></div>
                                    <h4>Nuit de louange <span>Abidjan Nord</span></h4>
                                    <div class="time">21:00  à l'aubre</div>
                                </div>
                                <div class="col-md-4">
                                    <ul class="hb-social">
                                        <li class="hb-fb"><a href="#"><i class="fa fa-facebook"></i></a></li>
                                        <li class="hb-tw"><a href="#"><i class="fa fa-twitter"></i></a></li>
                                        <li class="hb-plus"><a href="#"><i class="fa fa-plus"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="date">31<span>Dec</span></div>
                                    <h4>Traversé <span>2023/2024</span></h4>
                                    <div class="time">Toutes les regions EPCI</div>
                                </div>
                                <div class="col-md-4">
                                    <ul class="hb-social">
                                        <li class="hb-fb"><a href="#"><i class="fa fa-facebook"></i></a></li>
                                        <li class="hb-tw"><a href="#"><i class="fa fa-twitter"></i></a></li>
                                        <li class="hb-plus"><a href="#"><i class="fa fa-plus"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="line"></div>
                </div>
                <!-- Events Wrap -->
            </div>
        </div>

       
  </div>
</template>
<script>
import axios from 'axios';
import { JNEPCI_URL,JNEPCI } from '@/config'
// import truncate from 'html-truncate';
var moment = require('moment');
// import Loading from 'vue-loading-overlay';
// import 'vue-loading-overlay/dist/vue-loading.css';


export default {
    // components: {
    //         Loading
    //     },
    data: function () {
        return {
            actualites:[],
            bureau:[],
            JNEPCI:JNEPCI,
            isLoading:false
        }

    },
    mounted:function(){
        this.getDepartement()
        this.getBureau()
    },
    methods:{
            getDepartement: function() {
                // var truncate = require('html-truncate');
                moment.locale('fr');
                let app = this;
                app.isLoading = true;
                axios.get(JNEPCI_URL+'actualites')
                .then((response) => 
                {
                  console.log(response)
                  response.data.forEach(element => {
                      element.date = moment(element.created_at, "YYYYMMDD").fromNow();
                  });
                   
                    app.actualites = response.data
                    app.isLoading = false;
                })
                .catch((   ) => {
                    app.isLoading = false;
                });
            },
            getBureau: function() {
            let app = this;
            this.isLoading = true;
            axios.get(JNEPCI_URL+'bureau-regions')
            .then((response) => 
            {
              console.log(response.data)
                app.bureau = response.data
                this.isLoading = false;
            })
            .catch((   ) => {
                    
            });
        },
    }
}
</script>