<template>
    <div>
        <div class="slider-wrap">

<!-- Header -->
            <header class="container">
                <div class="col-md-6">
                    <div class="nav-left">
                        <ul>
                            <li class="has-sub-mega">
                                <a href="/">Accueil</a>
                                
                            </li>
                            <li class="has-sub-mega">
                                <a href="/a-propos">A propos</a>
                                <div class="mega-menu">
                                    <div class="col-md-9 m1">
                                        <h4>JNEPCI</h4>
                                        <p>le conseil exécutif de l’Eglise de Pentecôte de Côte d’Ivoire, dans le souci d’une meilleure organisation de ladite Eglise, a attribué à sa jeunesse, une plateforme d’expression pour son édification et sa croissance spirituelle</p>
                                        <!-- <p>Eleifend lobortis massa ligula at turpis. Quisque fringilla pharetra arcu, in tristique nibh.</p> -->
                                        <div class="space30"></div>
                                        <a class="menu-btn" href="/a-propos">Voir plus</a>
                                    </div>
                                    <!-- <div class="col-md-4 m2">
                                        <h5>PEOPLE / VOLUNTEERS</h5>
                                        <a href="people.html">People and Volunteers</a>
                                        <a href="people_single.html">People - Single</a>
                                        <div class="space25"></div>
                                        <h5>About us</h5>
                                        <a href="im_new.html">Im New</a>
                                        <a href="stories-single.html">About Us - Full Width</a>
                                        <a href="index-2.html">Testimonials</a>
                                        <a href="contact.html">Contact and Locations</a>
                                        <div class="space25"></div>
                                        <h5><a href="timeline.html">Timeline</a></h5>
                                    </div> -->
                                    <div class="col-md-3 m3">
                                        <img src="demo/8.jpg" class="img-responsive" alt="" />
                                        <div class="space40"></div>
                                        <h6>Connect with us</h6>
                                        <div class="m-social">
                                            <li class="hb-fb"><a href="https://web.facebook.com/jnepci/"><i class="fa fa-facebook"></i></a></li>
                                            <li class="hb-tw"><a href="https://twitter.com/JNEPCI"><i class="fa fa-twitter"></i></a></li>
                                            <li class="hb-plus"><a href="https://www.youtube.com/channel/UCapvFRK44oQ4CCKani_y9Cw"><i class="fa fa-youtube"></i></a></li>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="has-sub">
                                <a href="/departements">Departements</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="logo">
                        <a href="/"><img src="/logo-jnepci.png" class="img-responsive" width="35%" alt="" /></a>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="nav-right">
                        <ul>
                            <li class="has-sub">
                                <a href="/enseignements">Enseignements</a>
                            </li>
                            <li class="has-sub">
                                <a href="/actualites">Actualités</a>
                            </li>
                            <li><a href="/contact">Contact</a></li>
                        </ul>
                    </div>
                </div>
            </header>
            <!-- Header -->

            <div class="nav-left nav-mobile">
                <ul class="nav-mobile-ul">
                    <li class="has-sub-mega">
                        <a href="/">Accueil</a>
                    </li>
                    <li class="has-sub-mega">
                        <a href="/a-propos">A propos</a>
                    </li>
                    <li class="has-sub">
                        <a href="/departements">Departements</a>
                        
                    </li>
                    <li class="has-sub">
                        <a href="/enseignements">Enseignements</a>
                        
                    </li>
                    <li class="has-sub">
                        <a href="/actualites">Actualités</a>
                        
                    </li>
                    <li><a href="/contact">Contact</a></li>
                </ul>
            </div>

            <!-- Slider -->
            <div class="slider-wrapper theme-default">
                <div id="owl-homeslider" class="owl-carousel">
                    <div class="item">
                        <img alt="" class="img-responsive" src="demo/slide/1.jpg">
                        <div class="nivo-caption">
                            <h2>Retabissons <br>dans l'unité</h2>
                            <p>Néhémie 2:18</p>
                        </div>
                    </div>
                    <div class="item">
                        <img alt="" class="img-responsive" src="demo/slide/2.jpg">
                        <div class="nivo-caption">
                            <h2>Retabissons <br>dans l'unité</h2>
                            <p>Néhémie 2:18</p>
                        </div>
                    </div>
                    <div class="item">
                        <img alt="" class="img-responsive" src="demo/slide/3.jpg">
                        <div class="nivo-caption">
                            <h2>Retabissons <br>dans l'unité</h2>
                            <p>Néhémie 2:18</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {

    }
</script>